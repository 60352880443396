import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "@reach/router";
import styled from "styled-components";
import { Stack, Text } from "basis";
import { StringParam, useQueryParam } from "use-query-params";

import {
  Container,
  ErrorMessage,
  Icon,
  LoadingMessage,
} from "../../../components";
import { prepareWidget, useMerchantAuth } from "../../../core/auth";
import { MERCHANT_PAGE_ROUTES } from "../../../core/constants";

import { logger } from "../../../core";

import { merchantOktaConfig } from "../../../core/config";
import { MODE } from "../../../core/auth/constants";
import { navigate } from "gatsby";
import { BREAKPOINT, COLOR } from "../../../components/theme";
import Scene from "../../../layout/shared/Scene";

const CONFETTI_SIZE = "50px";
const SHADOW_SIZE = "40px";
const MAX_CONTAINER_WIDTH = "400px";
const MAX_CONTENT_WIDTH = "350px";

const LoginContainer = styled.div`
  position: relative;
  max-width: ${MAX_CONTAINER_WIDTH};
  margin: 0 auto;
  z-index: 1;
  margin-top: 4rem;

  #okta-sign-in {
    position: relative !important;
    border: none !important;
    margin: 0 !important;
  }

  #okta-sign-in.auth-container.main-container {
    box-shadow: none !important;
  }

  #okta-sign-in .okta-sign-in-header {
    display: none !important;
  }

  #okta-sign-in .o-form-head {
    display: none !important;
  }

  #okta-sign-in .identifier-container {
    top: 0 !important;
  }

  #okta-sign-in .auth-content {
    padding: 0 !important;
    width: ${MAX_CONTENT_WIDTH} !important;
  }

  @media only screen and (max-width: ${BREAKPOINT.SIZE_MOBILE}) {
    #okta-sign-in .auth-content {
      max-width: 100% !important;
    }
  }

  #okta-sign-in.main-container {
    background-color: transparent !important;
  }

  #okta-sign-in.auth-container .btn-disabled {
    opacity: 0.45;
    cursor: not-allowed;
  }

  #okta-sign-in.auth-container input[type="text"],
  #okta-sign-in.auth-container input[type="password"] {
    background-color: rgb(242, 242, 242) !important;
    border-width: 0px !important;
    border: none !important;
    outline: 0 !important;
  }

  #okta-sign-in.auth-container input[type="text"]:hover,
  #okta-sign-in.auth-container input[type="password"]:hover {
    outline: none !important;
  }

  #okta-sign-in.auth-container input[type="text"]:focus,
  #okta-sign-in.auth-container input[type="password"]:focus {
    outline: none !important;
  }

  #okta-sign-in.auth-container a:not(.button):active,
  #okta-sign-in.auth-container a:not(.button):link,
  #okta-sign-in.auth-container a:not(.button):visited {
    color: ${COLOR.BLACK};
  }

  #okta-sign-in.auth-container a:not(.button) {
    font-weight: 300;
    color: ${COLOR.LATITUDE_BLUE} !important;
    border-bottom: 1px solid rgba(0, 70, 170, 0.5);
    transition: background-color 200ms ease-out 0s,
      border-bottom-color 200ms ease-out 0s;
  }

  #okta-sign-in.auth-container a:not(.button):hover {
    border-bottom-color: ${COLOR.LATITUDE_BLUE};
    background-color: ${COLOR.SECONDARY_LIGHT_BLUE_25};
    text-decoration: none;
  }

  #okta-sign-in.auth-container a[data-se="help"] {
    display: none !important;
  }

  #okta-sign-in.auth-container .button-primary:hover:not(.btn-disabled) {
    background: rgb(0, 70, 170) !important;
  }

  #okta-sign-in .btn-secondary:not(.btn-disabled) {
    background-color: ${COLOR.WHITE} !important;
    color: rgb(0, 106, 255) !important;
    border: 1px solid rgb(0, 106, 255) !important;
  }

  #okta-sign-in .btn-secondary:hover:not(.btn-disabled) {
    background: rgb(0, 106, 255) !important;
    color: rgb(255, 255, 255) !important;
  }

  #okta-sign-in .auth-divider {
    background-color: transparent !important;
  }

  #okta-sign-in .auth-divider .auth-divider-text {
    text-transform: lowercase;
    font-weight: 600;
    color: ${COLOR.BLACK};
  }
`;

const ConfettiRight = styled(Icon)`
  display: none;

  @media screen and (min-width: ${BREAKPOINT.SIZE_TABLET}) {
    display: block;
    position: absolute;
    transform: rotate(180deg);
    top: -${CONFETTI_SIZE};
    right: -${CONFETTI_SIZE};
  }
`;

const ConfettiLeft = styled(Icon)`
  display: none;

  @media screen and (min-width: ${BREAKPOINT.SIZE_TABLET}) {
    display: block;
    position: absolute;
    left: -${CONFETTI_SIZE};
    bottom: -${CONFETTI_SIZE};
  }
`;

const ShadowContainer = styled.div`
  @media screen and (min-width: ${BREAKPOINT.SIZE_TABLET}) {
    &:before {
      position: absolute;
      display: block;
      content: " ";
      top: 1rem;
      left: 1rem;
      height: 90%;
      width: 90%;
      border: ${SHADOW_SIZE} solid ${COLOR.LIGHT_GREY};
      z-index: -1;
    }
  }
`;

const OktaContainer = styled.div`
  margin: 0 auto;
`;

const QUERY_PARAM_IDP = "idp";

const Login = ({ idpType }) => {
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [error, setError] = useState(null);
  const location = useLocation();
  const widgetRef = useRef();
  const { start, fetchProfile } = useMerchantAuth();

  const [idpId] = useQueryParam(QUERY_PARAM_IDP, StringParam);

  useEffect(() => {
    if (!widgetRef.current) {
      return;
    }

    start();

    const widget = prepareWidget({
      oktaConfig: merchantOktaConfig,
      mode: MODE.MERCHANT,
      targetElement: widgetRef.current,
      afterAuth: async () => {
        setAuthenticated(true);
        await fetchProfile();
        navigate(MERCHANT_PAGE_ROUTES.Default);
      },
      onError: (err) => {
        logger.error(`Partner Hub login error. ${JSON.stringify(err)}`);
        setError(err);
      },
      idpId,
      idpType,
    });

    return () => {
      widget.off();
      widget.remove();
    };
  }, [location.search, error, start, idpId]);

  if (isAuthenticated) {
    return (
      <Scene>
        <Scene.FullWidth>
          <LoadingMessage message="Setting up your profile.." />
        </Scene.FullWidth>
      </Scene>
    );
  }

  if (error?.message) {
    return (
      <Scene>
        <Scene.FullWidth>
          <ErrorMessage
            title={error?.message}
            description="Your credentials does not look correct. Please try again."
            additionalParams={{
              urlCancelLabel: "Back to login",
              urlCancel: MERCHANT_PAGE_ROUTES.Login,
            }}
          />
        </Scene.FullWidth>
      </Scene>
    );
  }

  return (
    <Scene infoBorder>
      <Scene.FullWidth>
        <LoginContainer>
          <ConfettiRight type="confetti" size={CONFETTI_SIZE} />
          <ConfettiLeft type="confetti" size={CONFETTI_SIZE} />

          <ShadowContainer>
            <Container bg="grey.t03" margin="4 0" padding="10 6" shadow>
              <Stack gap="4">
                <Text align="center" textStyle="heading4">
                  Welcome to Partner Hub
                </Text>

                <OktaContainer ref={widgetRef} />
              </Stack>
            </Container>
          </ShadowContainer>
        </LoginContainer>
      </Scene.FullWidth>
    </Scene>
  );
};

export default Login;
